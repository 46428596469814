.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* DESKTOP HOMEPAGE */
#MAIN-BODY{
  position: relative;
  width: 100%;
}

/* --------------------------- HEADER AREA ---------------- */
#HEADER-SPACE{
  position: relative;
  width: 100%;
  background-color: rgb(0, 0, 0);
}
.header-box{
  position: relative;
  width: 931px;
  height: 50px;
  margin: auto;
  left: auto;
  right: auto;

  /* border-color: cornsilk;
  border-width: 1px;
  border-style: dotted; */
}
.top-header-logo{
  position: absolute;
  padding-top: 5px;
  left: 15px;
}

.top-header-first-menu{
  position: absolute;
  left: 30px;
}
.top-header-first-menu li{
  display: inline;
  padding-right: 20px;
}
.top-header-first-menu li a{
  color: #16A068;
  text-decoration: none;
}

.top-header-second-menu{
  position: absolute;
  right:0px;
}
.top-header-second-menu li{
  display: inline;
  padding-left: 20px;  
}
.top-header-second-menu li a{
  color: #16A068;
  text-decoration: none;
}
/* ---------------------------------- LANDING ------------------------- */
#LANDING-SPACE{
  position: relative;
  width: 100%;
  margin: auto;
  left:auto;
  right: auto;
}
.landing_btn{
  background-color: #16a068;
  width: 70%;
  height: 60px;
  border-radius: 10px;
  border-width: 0;
  color: #fff;
  font-size: 22pt;
  cursor: pointer;
}
.landing_btn:hover{
  background-color: #01ff5e;
}
.landing-box{
  position: relative;
  width: 100%;
  margin: auto;
  left:auto;
  right: auto; 
  border: none;
  background-color: #000;
  /* background-image: linear-gradient(#1DDD64, #16a068); */
  /* background-image: linear-gradient(#16a068, #1DDD64); */

  /* border-color: cornsilk;
  border-width: 1px;
  border-style: dotted; */
}
.landing-box-footer{
  position: relative;
  width: 100%;
  height: 180px;
  margin: auto;
  left:auto;
  right: auto; 
  bottom: 0px;
  border: none;
  background-color: #fff;
  /* background-image: linear-gradient(#16a068, #000); */

  /* border-color: cornsilk;
  border-width: 1px;
  border-style: dotted; */
}
.Carousel1 img{
  padding-bottom: -4px;
  background-color:white;
}
.control-dots{
  background-color:rgb(255, 255, 255);
}

.bakiBTN{
  position:absolute;
  width: 100px;
  height:50px;
  left: 400px;
  top:200px;
  border:none;
  border-radius: 20px;
  text-decoration: none;
  font-weight: bold;
}
.bakiBTN a{
  text-decoration: none;
}

.bakiBTN1{
  position:absolute;
  background-color:#23AA63;
  width: 400px;
  height:50px;
  right: 175px;
  top:260px;
  border-width: 0px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
}
.bakiBTN1:hover{
  background-color: #000000;
}
.bakiBTN1 a{
  text-decoration: none;
}
.bakiBTN1 b{
  text-decoration: none;
  color: #ffffff;
  font-size: 12pt;
}
.baki_statement1{
  position: absolute;
  top: -45px;
  right: 190px;
  font-size: 60pt;
  color:#ffffff
}
.baki_statement1-b{
  position: absolute;
  top: 55px;
  right: 195px;
  font-size: 40pt;
  color:#ffffff
}
.baki_statement1-c{
  position: absolute;
  top: 100px;
  right: 190px;
  font-size: 46.5pt;
  color:#ffffff
}

.Carousel2 img{
  padding-bottom: -4px;
  background-color:white;
}
.control-dots{
  background-color:rgb(255, 255, 255);
}
.bakiBTN2{
  position:absolute;
  background-color:#000000;
  width: 400px;
  height:50px;
  right: 187px;
  top:300px;
  border-width: 0px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
}
.bakiBTN2:hover{
  background-color: #23AA63;
}
.bakiBTN2 a{
  text-decoration: none;
}
.bakiBTN2 b{
  text-decoration: none;
  color: #ffffff;
  font-size: 12pt;
}

.baki_statement2{
  position: absolute;
  top: -20px;
  right: 190px;
  font-size: 56pt;
  color:#ffffff
}
.baki_statement2-b{
  position: absolute;
  top: 70px;
  right: 190px;
  font-size: 41pt;
  color:#ffffff
}
.baki_statement2-c{
  position: absolute;
  top: 90px;
  right: 190px;
  font-size: 66pt;
  color:#ffffff
}

.bakiBTN3{
  position:absolute;
  width: 200px;
  height:50px;
  left: 100px;
  top:450px;
  border:none;
  border-radius: 20px;
  text-decoration: none;
  font-weight: bold;
}
.bakiBTN3 a{
  text-decoration: none;
}

.bakiBTN4{
  position:absolute;
  width: 200px;
  height:50px;
  left: 400px;
  top:200px;
  border:none;
  border-radius: 20px;
  text-decoration: none;
  font-weight: bold;
}
.bakiBTN4 a{
  text-decoration: none;
}
/* ---------------------------------- CONTENTS ------------------------ */
#CONTENT-SPACE{
  position: relative;
  width: 100%;
}
#ROLLED-OUT-1{
  position: relative;
  width: 100%;
  margin: auto;
  left: auto;
  right: auto;

  /* border-color: cornsilk;
  border-width: 1px;
  border-style: dotted; */
}
#ROLLED-OUT-1 h1{
  font-family: "Baloo";
  font-size: 3.5em;
  text-align: center;
  line-height: 1em;
}
.landing-btn{
  position:absolute; 
  bottom:0px; 
  left:53.5%; 
  width:428px; 
  height:67px; 
  border-radius:50px; 
  padding:5px 20px 5px 20px; 
  background-color:#1DDD64; 
  font-family:"Baloo"; 
  font-size:22pt; 
  color:white;
  border-width:0px;
}
.landing-btn:hover{
  background-color: #000;
}
.landing-btn2{
  position:absolute; 
  bottom:13%; 
  left:5%; 
  width:278px; 
  height:67px; 
  border-radius:50px; 
  padding:5px 20px 5px 20px; 
  background-color:#1DDD64; 
  font-family:"Baloo"; 
  font-size:22pt; 
  color:white;
  border-width:0px;
}
.landing-btn2:hover{
  background-color: #000;
}
.landing-btn-tablet{
  position:absolute; 
  bottom:0px; 
  left:53.5%;  
  height:67px; 
  border-radius:50px; 
  padding:5px 20px 5px 20px; 
  background-color:#1DDD64; 
  font-family:"Baloo"; 
  font-size:20pt; 
  color:white;
  border-width:0px;
}
.landing-btn-tablet:hover{
  background-color: #000;
}
.landing-btn-tablet2{
  position:absolute; 
  bottom:13%; 
  left:5%; 
  height:67px; 
  border-radius:50px; 
  padding:5px 20px 5px 20px; 
  background-color:#1DDD64; 
  font-family:"Baloo"; 
  font-size:18pt; 
  color:white;
  border-width:0px;
}
.landing-btn-tablet2:hover{
  background-color: #000;
}

.ro1-box{
  position: relative;
  width: 931px;
  height: 135px;
  background-image: linear-gradient(#1C5841, #203F3B);
  border-radius: 12px;

  padding: 40px 0px 40px 0px;

}
.ro1-box h3{
  position: absolute;
  font-size: 1.5em;
  font-family: "Actor";
  color: #23AA63;
  width: 600px;
  right:60px;
}
.ro1-box .serv-icon{
  position: absolute;
  background-image: url("./css_imgs/icon_allserve.png");
  width: 132px;
  height: 132px;
  border-color: white;
  border-width: 5px;
  border-radius: 50%;
  left: 50px;
}
#ROLLED-OUT-2{
  position: relative;
  width: 95%;
  margin: auto;
  left: auto;
  right: auto;
  margin-top: 50px;
  /* background-color: #47834a; */
  /* border-color: cornsilk;
  border-width: 1px;
  border-style: dotted; */

}
#ROLLED-OUT-2 h1{
  font-family: "Baloo";
  font-size: 3.5em;
  text-align: center;
  line-height: 1em;
}
.serv-icon-series-sheath{
  position: relative;
  width: 931px;
  height: 135px;
  /* background-color: bisque; */
  padding: 30px 0px 30px 0px;
}
.arrow-1{
  position: absolute;
  top:10px;
  left:200px;
}
.arrow-2{
  position: absolute;
  top:10px;
  left:430px;
}
.arrow-3{
  position: absolute;
  top:10px;
  left:665px;
}
.serv-icon-series-sheath .flow-icon-1{
  position: absolute;
  background-image: url("./css_imgs/icon2_register.png");
  width: 132px;
  height: 132px;
  border-radius: 50%;
  top: 30px;
  left:50px;
}
.serv-icon-series-sheath .flow-icon-2{
  position: absolute;
  background-image: url("./css_imgs/icon3_deposit.png");
  width: 132px;
  height: 132px;
  border-radius: 50%;
  top: 30px;
  left:274px;
}
.serv-icon-series-sheath .flow-icon-3{
  position: absolute;
  background-image: url("./css_imgs/icon4_track.png");
  width: 132px;
  height: 132px;
  border-radius: 50%;
  top: 30px;
  left:510px;
}
.serv-icon-series-sheath .flow-icon-4{
  position: absolute;
  background-image: url("./css_imgs/icon5_pay.png");
  width: 132px;
  height: 132px;
  border-radius: 50%;
  top: 30px;
  right:50px;
}

.ro2-box{
  position: relative;
  margin-top: 30px;
  width: 931px;
  height: 250px;
  background-image: linear-gradient(#1C5841, #203F3B);
  border-radius: 12px;
  padding:30px 0px 30px 0px;

}
.ro2-box h3{
  position: absolute;
  font-size: 2.2em;
  font-family: "Actor";
  color: #23AA63;
  width: 804px;
  top:20px;
  left:65px;
}
.ro2-box .serv-icon{
  position: absolute;
  background-image: url("./css_imgs/icon5_pay.png");
  width: 132px;
  height: 132px;
  border-radius: 50%;
  left: 50px;
}
.ro2-btn{
  position: absolute;
  width: 316px;
  height: 71px;
  background-image: linear-gradient(#16A068, #19CB5B);
  border-bottom-left-radius: 36px;
  border-top-left-radius: 36px;
  border-bottom-right-radius: 36px;
  border-top-right-radius: 36px;
  font-family: "Baloo";
  font-size: 1.8em;
  border:none;
  top:190px;
  right:60px;
  
}
.ro2-btn a{
  text-decoration: none;
  color: white;
}

.serv-icon-series-sheath .ico_label-1{
  position: absolute;
  top:155px;
  left:75px;
  font-family: "Actor";
}
.serv-icon-series-sheath .ico_label-2{
  position: absolute;
  top:155px;
  left:305px;
  font-family: "Actor";
}
.serv-icon-series-sheath .ico_label-3{
  position: absolute;
  top:155px;
  left:550px;
  font-family: "Actor";
}
.serv-icon-series-sheath .ico_label-4{
  position: absolute;
  top:155px;
  left:800px;
  font-family: "Actor";
}

#ROLLED-OUT-3{
  position: relative;
  width: 931px;
  height: 500px;
  margin: auto;
  left: auto;
  right: auto;
  margin-top: 50px;

  /* background-color: #ee9d9d; */
  /* border-color: cornsilk;
  border-width: 1px;
  border-style: dotted; */
}
#ROLLED-OUT-3 h1{
  font-family: "Baloo";
  font-size: 3.5em;
  text-align: center;
  line-height: 1em;
}
.ro3-box{
  position: relative;
  width: 931px;
  height: 135px;
  background-image: linear-gradient(#1C5841, #203F3B);
  border-radius: 12px;

  padding: 40px 0px 40px 0px;

}
.ro3-box h3{
  position: absolute;
  font-size: 1.5em;
  font-family: "Actor";
  color:#23AA63;
  width: 600px;
  right:70px;
}
.ro3-box .serv-icon{
  position: absolute;
  background-image: url("./css_imgs/icon6_shipment.png");
  width: 132px;
  height: 132px;
  border-radius: 50%;
  left: 50px;
}

#ROLLED-OUT-4{
  position: relative;
  width: 100%;
  margin: auto;
  left: auto;
  right: auto;
  margin-top: 10px;
  bottom:-80px;
  padding: 0px 0px 75px 0px;
  /* background-color: #fb61cd; */
  /* border-color: cornsilk;
  border-width: 1px;
  border-style: dotted; */
}
.ro4-box{
  position: relative;
  width: 931px;
  height: 130px;
  background-image: linear-gradient(#1C5841, #203F3B);
  border-radius: 12px;

  padding: 40px 0px 40px 0px;

}
.ro4-box h4{
  position: absolute;
  font-size: 1.2em;
  font-family: "Actor";
  color:#23AA63;
  top:50px;
  width: 504px;
  left:75px;
}
.signup_btn{
  position: absolute;
  width: 232px;
  height: 71px;
  top: 65px;
  right: 122px;
  background-image: linear-gradient(#16A068, #19CB5B);
  border: none;
  color: #EFEFEF;
  font-family: "Baloo";
  font-size: 2em;
  border-radius: 36px;
  
}
.signup_btn a{
  text-decoration: none;
  color:white;
}
/* ---------------------------------- FOOTER --------------------------- */
#FOOTER-SPACE{
  position: relative;
  width: 100%;
  background-color: rgb(0, 0, 0);
}
.footer-box{
  position: relative;
  width: 931px;
  margin: auto;
  left: auto;
  right: auto;
  padding-top: 20px;

  /* border-color: cornsilk;
  border-width: 1px; 
  border-style: dotted; */
}
.footer-box h1{
  color: cornsilk;
}
/* footer for tablet */
#FOOTER-TABLET-SPACE{
  position: relative;
  width: 100%;
  background-color: rgb(0, 0, 0);
}
.footer-tablet-box{
  position: relative;
  width: 100%;
  margin: auto;
  left: auto;
  right: auto;
  padding-top: 20px;

  /* border-color: cornsilk;
  border-width: 1px; 
  border-style: dotted; */
}
.footer-tablet-box h1{
  color: cornsilk;
}

/* footer contents */
.first-part-f{
  border-top: 20px;
  position: relative;
  width: 931px;
  height: 186px;
}
.logo-space{
  position: relative;
  width: 257px; 
  top: 55px;
  margin: auto;
  left: auto;
}
.second-part-f{
  position: relative;
  width: 931px;
  height: 204px; 
  color: aliceblue;
  /* font-family: 'Droid Sans'; */
}
.fslogan-space{
  position: relative;
  width: 700px;
  
  margin: auto;
  left:auto;
  right:auto;
  text-justify: auto;
}
.second-part-f p{
  color: #16A068;
  font-size: 16pt;
}
.third-part-f{
  position: relative;
  width: 931px;
  height: 120px;
  /* background-color: rgb(218, 208, 119, 0.2);    */
}
.mail_list{
  position: relative;
  width: 749px;
  height: 53px;

  margin: auto;
  left:auto;
  right:auto;
/* 
  border-color: blue;
  border-width: 2px;
  border-style: ridge; */
}
.mail_list b{
  position: absolute;
  font-size: small;
  font-weight: lighter;
  top:-20px;
  left:30px;
  margin: auto;
  margin-left: auto;
  text-align: center;
}
.prime_field{
  position: relative;
  width: 405px;
  height: 51.75px;
  border:none;
  padding-left: 20px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  font-size: 22pt;
  color:#19CB5B;
  
}
.prime_field:focus{
  outline: none;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b2b2b2;
  opacity: 1; /* Firefox */
}

.prime_button{
  position: relative;
  top:-1px;
  width: 320px;
  height: 54px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border:none;
  color:#fff;
  font-family: "Baloo";
  font-size: xx-large;
  background-image: linear-gradient(#16A068, #19CB5B);
}

.fourth-part-f{
  position: relative;
  width: 931px;
  height: 300px;
  /* background-color: rgb(218, 119, 210, 0.2);    */
}
.f-widget-1{
  position: absolute;
  width: 232.25px;
  height: 204px;
  /* background-color: rgba(51, 255, 129, 0.2);    */
}
.f1-list{ /*ul*/
  position: relative;
  top:-10px;
  font-family: "Actor";
  font-size: 18pt;
  color: #23AA63;
}
.f1-list li{
  display:block;
  padding-top: 10px;
  padding-left: 0px;
}
.f1-list li a{
  text-decoration: none;
  color: #23AA63;
}
.limage{
  position: relative;
  width: 37px;
  height: 37px;
  top:10px;
}
.blimage{
  width: 20px;
}

.f-widget-2{
  position: absolute;
  width: 232.25px;
  height: 204px;
  left:232.25px;
  /* background-color: rgba(185, 146, 62, 0.5);    */
}
.f2-list{
  position: relative;
  top: 5px;
  font-family: "Actor";
  font-size: 18pt;
  color: #23AA63; 
}
.f2-list li{
  display:block;
  padding-top: 5px;
  padding-left: 0px;
}
.f2-list li a{
  text-decoration: none;
  color: #23AA63;
}
.f-widget-3{
  position: absolute;
  width: 232.25px;
  height: 204px;
  left:464.50px;
  /* background-color: rgba(68, 137, 241, 0.5);    */
}
.f3-list{
  position: relative;
  top: 5px;
  font-family: "Actor";
  font-size: 18pt;
  color: #23AA63; 
}
.f3-list li{
  display:block;
  padding-top: 5px;
  padding-left: 0px;
}
.f3-list li a{
  text-decoration: none;
  color: #23AA63;
}
.f-widget-4{
  position: absolute;
  width: 232.25px;
  height: 204px;
  left:696.75px;
  /* background-color: rgba(235, 68, 241, 0.5);    */
}
.f4-list{
  position: relative;
  top: 5px;
  font-family: "Actor";
  font-size: 18pt;
  color: #23AA63; 
}
.f4-list li{
  display:block;
  padding-top: 5px;
  padding-left: 0px;
}
.f4-list li a{
  text-decoration: none;
  color: #23AA63;
}
.fifth-part-f{
  position: relative;
  width: 931px;
  height: 204px; 
}
.copyright-space{
  position: relative;
  width: 160px;
  height: 30px;  
  top: 75px;
  margin: auto;
  left: auto;
  right: auto;  
  color: #b2b2b2;
}
.copyrightYear{
  color: #16A068;
}

/* LOGIN SPACE */
.log-form-space{
  position: relative;
  width: 953px;
  margin: auto;
  left: auto;
  right: auto;
}
.log-form-space h1{
  position: relative;
  font-family: "Baloo";
  font-size: 56pt;
  text-align: center;
}

.login-form{
  position: relative;
  width: 932px;
  height: 300px;
  top: -50px;
  margin: auto;
  left: auto;
  right: auto;
  padding: 62px 0px 20px 65px;

  border-color: #e4e4e4;
  border-width: 1pt;
  border-style: solid;
  border-radius: 12px;

  margin-bottom: 30px;
}
.login-form .txt-field:focus{
  outline-width: 2px;
  outline-color: #16A068;
}

.login-form .label{
  font-size: 20pt;
  color: #c4c4c4;
}

.login-form .txt-field{
  position: absolute;
  width: 624px;
  height: 46px;
  font-size: 20pt;
  background-color: #c4c4c4;
  padding-left: 26px;
  left: 220px;

  border: none;
  border-radius: 5px;
}
.login-form .log-btn{
  position: absolute;
  background-image: linear-gradient(#16A068, #19CB5B);
  border: none;
  width: 282px;
  height: 71px;
  font-family: 'Baloo';
  color: white;
  font-size: 22pt;
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
  left: 350px;
}
.login-form .log-btn:hover{
  background-image: linear-gradient(#2fe79e, #19CB5B);
}
.log-btn a{
  color: white;
  font-size: 22pt;
  font-family: "Baloo";
  text-decoration: none;
}

.login-form .log-cancel-btn{
  position: absolute;
  background-color: #000000;
  border: none;
  width: 252px;
  height: 71px;
  font-family: 'Baloo';
  color: white;
  font-size: 22pt;
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
  left: 620px;
}
.login-form .log-cancel-btn:hover{
  background-image: linear-gradient(#7c7c7c, #000000);
}
.log-cancel-btn a{
  color: white;
  font-size: 22pt;
  font-family: "Baloo";
  text-decoration: none;
}
.error_crendential{
  margin:auto;
  width: 50%;
}
.error_back_btn{
  position:relative; 
  background-color:black;
  color:#ffffff; 
  padding:10px 50px 10px 50px; 
  border-radius:20px; 
  border-width:0px; 
  font-size:14pt;
}
.error_back_btn:hover{
  color:#19CB5B;
}

.error_forgot_btn{
  position:relative; 
  background-color:white;
  color:rgb(25, 203, 91); 
  padding:10px 50px 10px 50px; 
  border-radius:30px; 
  border-width:0px; 
  font-size:12pt;

}
.error_forgot_btn:hover{
  text-decoration: underline;
}

.email_retriever{
 width: 80%;
 height:30px;
 border-width: 1px;
 border-style: solid;
 border-color: #e5e5e5;
 font-size:12pt;
 padding-left: 10px;
 padding-right: 10px;
}
.email_retriever:focus{
  outline:none;
}

::placeholder{
  color: rgb(224, 224, 224);
}
/* REGISTER SPACE */
.error_warning{
  position: fixed;
  background-color: #16A068;
  z-index: 2;
  top:0;
  width: 100%;
  height:50px;
}
.centered_warning{
  position: relative;
  width: 900px;
  height: 100%;
  margin: auto;
  left: auto;
}
.errorWarningCloseBTN{
  position: absolute;
  background-color: rgb(0, 0, 0);
  border-width: 0px;
  color: white;

  top:10px;
  right:0px;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
}
.error_warning h4{
  position: absolute;
  top:-10px;
  right: 100px;
}
.register-form-space{
  position: relative;
  width: 953px;
  margin: auto;
  left: auto;
  right: auto;
}
.register-form-space h1{
  position: relative;
  font-family: "Baloo";
  font-size: 56pt;
  text-align: center;
}

.register-form{
  position: relative;
  width: 932px;
  height: 460px;
  top: -50px;
  margin: auto;
  left: auto;
  right: auto;
  padding: 62px 0px 50px 65px;

  border-color: #e4e4e4;
  border-width: 1pt;
  border-style: solid;
  border-radius: 12px;

  margin-bottom: 30px;
}
.register-form .label{
  font-size: 20pt;
  color: #c4c4c4;
}

.register-form .txt-field{
  position: absolute;
  width: 624px;
  height: 46px;
  font-size: 20pt;
  background-color: #c4c4c4;
  padding-left: 26px;
  left: 220px;

  border: none;
  border-radius: 5px;
}
.register-form .txt-field:focus{
  outline-width: 2px;
  outline-color: #16A068;
}
.register-form .txt-field-4{
  position: absolute;
  width: 520px;
  height: 46px;
  font-size: 20pt;
  background-color: #c4c4c4;
  padding-left: 26px;
  left: 325px;

  border: none;
  border-radius: 5px;
}
.register-form .txt-field-4:focus{
  outline-width: 2px;
  outline-color: #16A068;
}

.register-form .indicator{
  position: absolute;
  border: none;
  top:0px;
}
.register-form .uchecker{
  position: absolute;
  padding:10px 10px 10px 10px;
  top:65px;
  right:65px;
  border: #e4e4e4;
  border-style: solid;
  border-width: 2px;
  border-radius: 6px;
}
.register-form .echecker{
  position: absolute;
  padding:10px 10px 10px 10px;
  top:142px;
  right:10px;
  border: #e4e4e4;
  border-style: solid;
  border-width: 2px;
  border-radius: 6px;
}
.register-form .echecker2{
  position: absolute;
  padding:10px 10px 10px 10px;
  top:142px;
  right:20px;
  border: #e4e4e4;
  border-style: solid;
  border-width: 2px;
  border-radius: 6px;
}
.register-form .echecker3{
  position: absolute;
  padding:10px 10px 10px 10px;
  top:142px;
  right:65px;
  border: #e4e4e4;
  border-style: solid;
  border-width: 2px;
  border-radius: 6px;
}
.register-form .pchecker1{
  position: absolute;
  padding:10px 10px 10px 10px;
  top:257px;
  right:65px;
  border: #e4e4e4;
  border-style: solid;
  border-width: 2px;
  border-radius: 6px;
}
.register-form .log-btn{
  position: absolute;
  background-image: linear-gradient(#16A068, #19CB5B);
  border: none;
  width: 282px;
  height: 71px;
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
  left: 90px;
}
.register-form .log-btn:hover{
  position: absolute;
  background-image: linear-gradient(#2fe79e, #19CB5B);
  border: none;
  width: 282px;
  height: 71px;
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
  left: 90px;
}
.log-btn b{
  color: rgb(2, 2, 2);
  font-size: 22pt;
  font-family: "Baloo";
  text-decoration: none;
}

.register-form .log-clear-btn{
  position: absolute;
  background-color: black;
  border: none;
  width: 240px;
  height: 71px;
  left: 353px;
}

.register-form .log-clear-btn:hover{
  position: absolute;
  background-image: linear-gradient(#7c7c7c, #000000);
  border: none;
  width: 240px;
  height: 71px;
  left: 353px;
}
.log-clear-btn b{
  color: #c4c4c4;
  font-size: 22pt;
  font-family: "Baloo";
  text-decoration: none;
}
.register-form .log-canceler-btn{
  position: absolute;
  background-color: #c4c4c4;
  border: none;
  width: 282px;
  height: 71px;
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
  left: 593px;
}
.register-form .log-canceler-btn:hover{
  position: absolute;
  background-color: #c4c4c4;
  background-image: linear-gradient(#ebebeb, #c4c4c4);
  border: none;
  width: 282px;
  height: 71px;
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
  left: 593px;
}
.log-canceler-btn a{
  color: rgb(0, 0, 0);
  font-size: 22pt;
  font-family: "Baloo";
  text-decoration: none;
}
.log-canceler-btn a:hover{
  color: green;
  font-size: 22pt;
  font-family: "Baloo";
  text-decoration: none;
}
.log-canceler-btn b{
  color: rgb(0, 0, 0);
  font-size: 22pt;
  font-family: "Baloo";
  text-decoration: none;
}

@keyframes contextRegisterStatus {
  from {background-color: #16A068;}
  to {background-color: #19CB5B;}
}

/* FAQs Page */
.faqs-space{
  position: relative;
  margin: auto;
  left: auto;
  right: auto;
  width: 800px;
  bottom: 30px;
  /* border-style: dotted;
  border-width: 2px;
  border-color: #16A068; */
}
.faqs-space h1{
  position: relative;
  font-family: "Baloo";
  font-size: 56pt;
  text-align: left;
}
.faqs-space h2{
  position: relative;
  font-family: "Baloo";
  font-size: 22pt;
  text-align: left;
}
.faqs-space p{
  position: relative;
  font-family: "Actor";
  font-size: 18pt;
  text-align: left;
}

/* Services Space */
.search-space{
  position: relative;
  background-color: #ececec;
  width: 798px;
  height: 41px;
  font-size: 16pt;
  padding-left:5px;
  border-width: 0px;
  border-radius: 10px;
}
.service_search_bar{
  position: relative;
  font-size: 16pt;
  padding-top:5px;
  padding-left:10px;
  width: 605px;
  height: 31px;
  background-color: rgb(0, 0, 0, 0);
  border-radius: 10px;
  border-width: 0px;
}
.service_search_bar:focus{
  outline: none;
}
.search-btn{
  position: absolute;
  right: -20px;
}
.search-search{
  position: absolute;
  color:white;
  font-weight: normal;
  top:4px;
  width: 150px;
  height: 30px;
  
  right:-10px;
  padding-left: 60px;
  padding-right: 30px;
  /* border-width: 1px;
  border-color: #61dafb;
  border-style: dotted; */
}
.search-btn:hover, .search-search:hover{
  color: #19CB5B;
}
.loading-indicator-area{
  position: relative;
}
.loading-indicator-area h3{
  position: relative;
  top:2px;
  left: 30px;
  font-style: italic;
  font-size: small;
}
.rotate-bg{
  position: absolute;
  background-color: black;
  border-radius: 50%;
  top:0px;
  width: 21px;
  height: 21px;
  padding:1px;
}
.wait-rotate{
  position: relative;
  border-radius: 7px;
  top:2px;
  margin:auto;
  left: auto;
  width: 17px;
  height: 17px;
  -webkit-animation:spin 1s linear infinite;
  -moz-animation:spin 1s linear infinite;
  animation:spin 1s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.services-space{
  position: relative;
  margin: auto;
  top:0px;
  left: auto;
  right: auto;
  width: 800px;
  bottom: 50px;
  margin-bottom: 100px;
  /* border-style: dotted;
  border-width: 2px;
  border-color: #16A068; */
}
.services-space h1{
  position: relative;
  font-family: "Baloo";
  font-size: 44pt;
  text-align: left;
}
.services-space .subtitle{
  position: relative;
  top: 60px;
  font-family: "Baloo";
  font-size: 18pt;
  text-align: left;
}
/*Spinner*/
.spin-container {
  text-align: center;
  margin-top: 100px;
}
.spin {
  border: 3px solid #19CB5B;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
#loader2 {
  border: 3px solid #16A068;
  width: 220px;
  height: 220px;
  position: relative;
  top: -216px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
#loader3 {
  border: 3px solid #000000;
  width: 240px;
  height: 240px;
  position: relative;
  top: -452px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
#loader4 {
  border: 3px solid #16A068;
  width: 260px;
  height: 260px;
  position: relative;
  top: -708px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
@keyframes rotate {
  0% {
    transform: rotateZ(-360deg)
  }
  100% {
    transform: rotateZ(0deg)
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateZ(360deg)
  }
  100% {
    transform: rotateZ(0deg)
  }
}
#text {
  color: #000000;
  font-family: Arial;
  font-size: 15px;
  position: relative;
  top: -857px;
}
/*Spinner*/
.default-search-display-content-container{
  position: relative;
  background-color: #C4C4C4;
  margin: auto;
  left:-40px;
  border-radius: 10px;
  width: 820px;
  height:322px;
  margin-top:50px;
  margin-bottom: 50px;
}
.SVG-part{
  position: absolute;
  top:0px;
  right: 0px;
}
.gallery-image{
  position: absolute;
  top:0px;
  left: 0px;
  width: 605px;
  height:322px;
  border-radius: 10px;
}
.task-title{
  position: absolute;
  font-family: 'Baloo', Courier, monospace;
  text-align: center;
  font-size: 19pt;
  color: white;
  top: 40px;
  right:20px;
  width: 260px;
  /* border-color: red;
  border-style: dotted;
  border-width: 2px; */
}
.task-price{
  position: absolute;
  font-family: 'Actor', Courier, monospace;
  text-align: center;
  font-size: 14pt;
  color: white;
  top: 80px;
  right:20px;
  width: 260px;
  /* border-color: red;
  border-style: dotted;
  border-width: 2px; */
}
.task-description{
  position: absolute;
  font-family: 'Segoe UI', sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: 14pt;
  color: white;
  top: 135px;
  right:20px;
  width: 260px;
  /* border-color: red;
  border-style: dotted;
  border-width: 2px; */
}
.task-button{
  position: absolute;
  background-image: linear-gradient(#1ddd64, #16A068);
  font-family: 'Baloo', Courier, monospace;
  font-size: 14pt;
  color: rgb(255, 255, 255);
  bottom: 30px;
  right:180px;
  width: 140px;
  height: 40px;
  border-width: 0px;
  border-radius: 30px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.task-button:hover{
  background-image: linear-gradient(#16A068, #16A068);
  color:#fff;
}
.goportfolio-button{
  position: absolute;
  background-image: linear-gradient(#16A068, #19CB5B);
  font-family: 'Baloo', Courier, monospace;
  font-size: 14pt;
  color: rgb(255, 255, 255);
  bottom: 30px;
  right:30px;
  width: 150px;
  height: 40px;
  border-width: 0px;
  border-radius: 30px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.goportfolio-button:hover{
  background-image: linear-gradient(#16A068, #16A068);
  color:#fff;
}

.quotation_rounder{
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.1);
  z-index: 1;
}
.quotation_floater{ /*floating quoter*/
  position: relative;
  margin: auto;
  top: 50px;
  left: auto;
  width: 600px;
  background-color: #ffffff;
  padding-bottom: 50px;
  background-repeat: no-repeat;

  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #ffffff;
  text-align: center;
}
.ordersuccess-btn1{
  background-color: #16A068;
  color:#fff;
  padding:10px 30px 10px 30px;
  border-width: 0px;
  border-radius: 4px;
  margin:1px;
}
.ordersuccess-btn1:hover{
  background-color: #000;
}
.ordersuccess-btn2{
  background-color: #16A068;
  color:#fff;
  padding:10px 30px 10px 30px;
  border-width: 0px;
  border-radius: 4px;
  margin:4px;
}
.ordersuccess-btn2:hover{
  background-color: #000;
}
#popup-2 .popup-content{
  width: 100px;
}
.quote_form{
  position: relative;
  margin: auto;
  left: auto;
  width: 91%;
  padding-bottom: 20px;

  /* border-width: 1px;
  border-color: red;
  border-style: dashed; */
}
/* .quote_form input{
  position: absolute;
  top:
  left: 0px;
  width: 99%;
} */
/* .quote_form input:focus{
  outline: none;
} */
.order_warning{
  position: relative;
  top:-20px;
  color:rgb(255, 0, 0);
  font-size: 10pt;
}
.profcircle{
  background-color: #ffffff;
  height: 40px;
  margin-bottom: 20px;
}
.ordercircle{
  background-color: #ffffff;
  color:#16a54b;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.job_options{
  position: relative;
  top:0px;
  width: 100%;
}
.quotation_text_area{
  margin-top: 20px;
  width: 97%;
  height: 250px;
  padding:7px;
  margin-bottom: 20px;
}
.quotation_text_area:focus{
  outline: none;
}
.quotation_file_upload{
  position: absolute;
  background-color: #16a54b;
  color:white;  
  left:0px;
  bottom:0px;
  margin-top: 20px;
  width: 200px;
  border-width: 0px;
}
.quotation_submit_btn{
  position: absolute;
  background-color: #16a54b;
  color:white;
  right:0px;
  bottom:0px;
  width: 200px;
  border-width: 0px;
  border-radius: 20px;
  padding:5px 5px 5px 5px;
}
.quotation_submit_btn:hover{
  background-color: #62796a;
}
.escape_area{
  position: absolute;
  width: 100%;
  height: 1000px;
  background-color:rgb(0, 0, 0, 0.01);
}
.escape_btn{
  position: absolute;
  font-family: Baloo;
  font-size: large;
  color: #000000;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  top:-2px;
  right:-50px;
  border-radius: 10px;
  border-width: 0px;
}
.escape_btn:hover{
  color: red;
}
/* Dashboard Spaces*/
#MAIN-DASHBOARD{
  position: relative;
  margin: auto;
  left: auto;
  width: 931px;

  /* border-width: 2px;
  border-style: dotted;
  border-color: #19CB5B; */
}
#MAIN-DASHBOARD h1{
  color:black;
}
#MAIN-DASHBOARD h4{
  color:black;
  text-align: center;
}

.namesearchspace{
  position: relative;
  width: 100%;
  height: 46px;
  background-color: #16A068;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}
.searchfield{
  position:absolute; 
  top:12px; 
  left:20px; 
  width:570px;
  height:20px;
  border-width: 0px;
  color: #000000;
  font-family:  'Actor',Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
}
.searchfield:focus{
  outline: 0px;
}
.recordedBTN{
  color: #ffffff;
}
.recordedBTN:hover{
  color: #2fe79e;
}

.control-buttons{
  position: relative;
  background-color: #ffffff;
  width: 100%;
  height:35px;
  margin-top:49px;
}
.controller{
  position: absolute;
  top:-11px;
  right: 193px;
}
.controller li{
  display: inline;
  background-color: #000000;
  color:#ffffff;
  font-Size:15pt; 
  font-Family:Actor;

  padding:5px 42px 50px 43px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.controller li:hover{
  background-color: #16A068;
  padding:5px 43px 50px 43px;
  cursor: pointer;
}
.inquirybtn{
  position:absolute; 
  right:0px; 
  width:193px; 
  height:35px; 
  background-color: #108053; 
  border-width:0px;
  border-top-right-radius:10px; 
  border-Top-Left-Radius:10px; 
  color:#ffffff;
  font-Size:15pt; 
  font-Family:Actor;
  cursor: pointer;
}
.inquirybtn:hover{
  background-color:#16A068;
}
.control-room{
  position: relative;
  background-color: #16A068;
  width: 100%;

  margin-top:0px;
  margin-bottom: 20px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.search_u_result{
  position: relative;
  top: 0px;
  width: 70%;
  color:white;
  padding: 20px 20px 20px 20px;
  margin-left: 20px;
  margin-bottom: 50px;

}
.project-listing-space{
  position: relative;
  background-color: #16A068;
  margin: auto;
  top: 0px;
  width: 100%;
  color:white;
  padding: 20px 0px 25px 0px;
  margin-bottom: 90px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.project-lists{
  position: relative;
  background-color: white;
  width: 94%;
  margin: auto;
  color: #000;
  padding-bottom: 20px;

  margin-top: 10px;
  border-radius: 5px; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.user-orders{
  position: relative;
  left:auto;
  margin-bottom: 20px;
}

.user-orders li{
  display: inline-table;
  padding: 0px 0px 0px 0px;
  width: 95%;
  background-color: #f8f8f8;
  border-radius: 5px;
}
.user-orders li .order-box{
  background-color: #16A068;
}
.admin-userorder-console-btn-assigner-btn{
  background-color: #16A068;
  color: white;
  font-size: 12pt;
  border-width: 0px;
  padding:5px 20px 5px 20px;
  margin-right: 1px;

  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.admin-userorder-console-btn-assigner-btn:hover{
  background-color: #000;
  color: #16A068;
}
.admin-userorder-console-btn-preview-btn{
  background-color: #16A068;
  color: white;
  font-size: 12pt;
  border-width: 0px;
  padding:5px 20px 5px 20px;
  margin-right: 1px;

}
.admin-userorder-console-btn-preview-btn:hover{
  background-color: #000;
  color: #16A068;
}
.admin-userorder-console-btn-marker-btn{
  background-color: #16A068;
  color: white;
  font-size: 12pt;
  border-width: 0px;
  padding:5px 20px 5px 20px;
  margin-right: 1px;

}
.admin-userorder-console-btn-marker-btn:hover{
  background-color: #000;
  color: #16A068;
}
.admin-userorder-console-btn-delete-btn{
  background-color: #16A068;
  color: white;
  font-size: 12pt;
  border-width: 0px;
  padding:5px 20px 5px 20px;

  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.admin-userorder-console-btn-delete-btn:hover{
  background-color: #000;
  color: #16A068;
}
/*Online Indicator*/
  .off-sign2{
    position: absolute;
    bottom: 3px;
    left:-12px; 
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #d1d1d1;
  }
  .off-sign{
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: lime;
    animation-name: onlineIndicator;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  @keyframes onlineIndicator {
  0% {background-color:lime; left:65px; top:-15px;}
  37% {background-color:rgb(10, 71, 35); left:65px; top:-15px;}
  40%  {background-color:darkgreen; left:65px; top:-15px;}
  45%  {background-color:green; left:65px; top:-15px;}
  48%  {background-color:yellowgreen; left:65px; top:-15px;}
  50%   {background-color:lime; left:65px; top:-15px;}
  55%  {background-color:yellowgreen; left:65px; top:-15px;}
  65%  {background-color:green; left:65px; top:-15px;}
  70%  {background-color:darkgreen; left:65px; top:-15px;}
  78% {background-color:rgb(10, 71, 35); left:65px; top:-15px;}
  100% {background-color:lime; left:65px; top:-15px;}
  }


.usersearch-single-result-btn{
  position: relative;
  float: right;
  right:5px;
  background-color: #16a068;
  z-index: 0;
  color:#fff;
  border-width: 0px;
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
}
.usersearch-single-result-btn:hover{
  background-color: #000000;
  color:#fff;
}
.activity-links-btn{
  position:relative; 
  top:30px; 
  font-size:13pt;
  color:#16a068; 
  cursor:pointer;
}
.activity-links-btn:hover{
  color:#000; 
  font-weight: 600;
}
.init-activity-links-btn{
  position:relative; 
  top:15px; 
  font-size:13pt;
  color:#16a068; 
  cursor:pointer;
}
.init-activity-links-btn:hover{
  font-weight: 600;
  color:#fff
}
.listed-users-in-chat-room li{
  position:relative; 
  background-color:#fff;
  display: inline-block;
  width: 25%;
  width:25%; 
  height:250px;
  padding-right: 20px;
  margin:20px;
  text-align: center;
}
.assignto-close-btn{
  position:absolute; 
  top:0px; 
  right:-35px;
  border-width:0;
  background-color:#ffffff;
  color:rgb(0, 0, 0); 
  border-radius:5px; 
  width:30px; 
  height:30px;
}
.assignto-close-btn:hover{
  background-color: #16a068;
  color: white;
}
.assigment-confirm-btn{
  position:relative; 
  margin:auto; 
  width:100%;
  background-color: #16A068;
  color: #fff;
  padding:5px 10px 5px 10px;
  margin-bottom: 5px;

  border-width: 0px;
  border-radius: 20px;
}
.assigment-confirm-btn:hover{
  background-color: #000000;
  color: #16A068;
}
.completed-switcher-btn{
  background-color: #000;
  padding: 5px 20px 5px 20px;
  border-width: 0px;
  border-style: none;
  border-radius: 5px;
  color: #fff;
}
.completed-switcher-btn:hover{
  background-color: #16a068;
  color: #fff;
}

.order_new_status_ribbon{
  position:absolute; 
  background-image: url("./css_imgs/new_ribbon.png");
  top:-5px;
  right:-5px;
  width: 100px;
  height:99px;
}
.order_complete_status_ribbon{
  position:absolute; 
  background-image: url("./css_imgs/complete_ribbon.png");
  top:-5px;
  right:-5px;
  width: 100px;
  height:99px;
}

.services-viewer-space{
  position: relative;
  background-color: #16A068;
  margin: auto;
  top: 0px;
  width: 100%;
  color:white;
  padding: 20px 0px 15px 0px;
  margin-bottom: 90px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.services-lists{
  position: relative;
  background-color: white;
  width: 94%;
  height: 370px;
  margin: auto;
  color: #000;

  margin-top: 10px;
  border-radius: 5px; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.internal-comms{
  position: relative;
  background-color: white;
  width: 94%;
  margin: auto;
  color: #000;

  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.inquiries-series{
  position: relative;
  background-color: white;
  width: 94%;
  margin: auto;
  color: #000;

  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.portfolio-viewer-space{
  position: relative;
  background-color: #16A068;
  margin: auto;
  top: 0px;
  width: 100%;
  color:white;
  padding: 20px 0px 15px 0px;
  margin-bottom: 90px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.portfolio-lists{
  position: relative;
  background-color: white;
  width: 94%;
  margin: auto;
  color: #000;

  margin-top: 10px;
  border-radius: 5px; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.creaseBTN1{
  position:absolute; 
  background-color:#16a068;
  border-width:0px; 
  border-radius:5px;  
  width:30px; 
  right:74px; 
  top:0px; 
  padding-top:2px; 
  padding-bottom:0px;
}
.creaseBTN1:hover{
  background-color:#000;
}
.creaseBTN2{
  position:absolute; 
  background-color:#16a068;
  border-width:0px; 
  border-radius:5px;  
  width:30px; 
  right:0px; top:0px; 
  padding-top:2px; 
  padding-bottom:0px;
}
.creaseBTN2:hover{
  background-color:#000;
}
.inquiries-switcher-btn{
  position:relative; 
  background-color:#16a068; 
  flex-direction: row;
  right: 0px;
  color:#fff; 
  padding: 5px 30px 5px 30px;  
  border-width:0px;
  margin-left: 1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.inquiries-switcher-btn:hover{
  background-color:#000; 
  color:#fff; 
}

.output-room{
  position: relative;
  background-color: #ffffff;
  width: 100%;
  height:35px;
  margin-top: 50px;
  z-index: 1;
}
.output-controls{
  position: absolute;
  left: 30px;
  top:11px;
  margin: auto;
}
.output-controls li{
  display: inline;
  background-color: #000000;
  color:#ffffff;
  font-Size:15pt; 
  font-Family:Actor;

  padding:5px 43px 8px 43px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.output-controls li:hover{
  background-color: #16A068;
  padding:5px 43px 8px 43px;
  cursor: pointer;
}

.user-classes-room{
  position: relative;
  background-color: #ffffff;
  width: 100%;
  border-radius: 10px;

  border-style: solid;
  border-width: 2px;
  border-color: #e8e8e8;

  margin-top: 8px;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-bottom: 100px;
}
.user_strip ul{
  border-color: #b8b8b8;
  padding: 5px 0px 5px 10px; 
  border-width: 0.1px;
  border-style: solid;
  margin-bottom: -1px;
}
.user_strip ul:hover{
  background-color: #d0d0d0;
  cursor: pointer;
}

.user-floater{
  position: fixed;
  top:0px;
  width: 100%;
  height: 100%;
  margin: auto;
  left:0;
  z-index: 3;
  background-color: rgba(0,0,0,0.6);
}
.user-floater-pad{
  position: relative;
  width: 70%;
  height: 100%;
  margin: auto;
  z-index: 3;
  background-color: rgba(0,0,0,0.6);
}
.user-floats-center{
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 50%;
  margin: auto;
  left: auto;

  top: 100px;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  z-index: 3;
}
.user_f_frontier{
  position:relative;
  background-color: #e6e6e6;
  width: 40%;
  text-align: center;
  padding: 10px 10px 10px 10px;
}
.id_f_space{
  position: relative;
  background-color: #ffffff;
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 50%;
  padding: 5px 5px 5px 5px;
}
.user_f_editables{
  position:absolute;
  background-color: #ffffff;
  top:30px;
  right: 50px;
  width: 40%;
  text-align: center;
  margin: auto;
  padding: 10px 10px 30px 10px;
}

.profile_update_succ_btn{
  background-color:#16a068;
  color:white;
  border-width:0px; 
  border-radius:20px; 
  padding:10px 50px 10px 50px;
}
.profile_update_succ_btn:hover{
  background-color:#000;
  color:white;
}
/*LOGIN SPINNER*/
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #65fdc0;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #16A068 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*USER DELETE SPINNER from https://loading.io/css/*/
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/*temporary dashboard*/
.temp_servport_panel{
  position: relative;
  /* background-color: #2fe79e; */
  margin: auto;
  left: 0;
  width: 90%;
  margin-bottom: 20px;
  border-radius: 0px;
  padding: 20px 50px 20px 20px;
}
.temp_form_serv{
  position: relative;
  margin: auto;
  left: auto;
  background-color: #ffffff;
  width: 100%;
  padding: 20px 20px 20px 20px;
  /* border-width: 2px;
  border-style: dotted;
  border-color: #e0ffeb;
  margin-left:100px; */
}
.temp_form_port{
  position: relative;
  margin: auto;
  left: auto;
  background-color: #ffffff;
  width: 100%;
  padding: 20px 20px 20px 20px;
  /* border-width: 2px;
  border-style: dotted;
  border-color: #e0ffeb;
  margin-left:100px; */
}
.dropbox-uploader{
  width: 80px;
  background-color: #000000;
  color: white;
  padding: 7px 10px 10px 10px;
}
.portfolio-click-add{
  position: absolute;
  background-color: #ebfcf6;
  top:94px;
  right:40px;
  width: 350px;
  height: 250px;
  border-radius: 5px;
}
.portfolio_textarea:focus{
  outline: none;
}
.services-portfolio-switch-btn{
  background-color: #16A068;
  color:#fff;
  padding:5px 20px 5px 20px;
  border-radius: 20px;
  border-width: 0px;
}
.services-portfolio-switch-btn:hover{
  background-color: #000;
  color: #fff;
}

/*PORTFOLIO FRONT PAGE*/
.masterspace{
  width: 100%;
  margin-bottom: 200px;

    /* border-color: #ff0000;
    border-width: 2px;
    border-style: dashed; */

  margin-bottom: 100px;
  padding-bottom: 10px;
}
.masterclass{
  position: relative;
  width: 100%;
  height: 220px;
  text-align: center;
  background-color: #fff;
  padding-top: 20px;

  /* border-color: #19CB5B;
  border-width: 2px;
  border-style: dashed; */
}
.masterclass h2{
  position: relative;
  font-family: "Baloo";
  font-size: 28pt;
}
.masterclass h1{
  position: relative;
  bottom:100px;
}
.masterpiecebox{
  position: relative;

  /* border-color: #19CB5B;
  border-width: 2px;
  border-style: dashed; */

  padding-bottom: 50px;
}
.masterpiecebox h3{
  position: relative;
  top:-35px;
  font-family: "Baloo";
  font-size: 20pt;
}
.view-more-btn{
  position: absolute;
  top:50px;
  right:148px;
  background-color: #16A068;;
  color: #fff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  top:0px;

  text-align: center;
  padding:10px 30px 10px 30px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-width: 0px;
}
.view-more-btn:hover{
  background-color: #16A068;
  color: #fff;
  font-weight: 700;
}

.like-this-btn{
  position: absolute;
  top:50px;
  right:0px;
  background-color: #000;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:white;
  top:0px;

  text-align: center;
  padding:10px 30px 10px 30px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-width: 0px;
}
.like-this-btn:hover{
  color: #16A068;
}

.port_breadcrumbs{
  position: relative;
  top: -90px;
  width: 20%;
  height: 10%;
  margin: auto;
}
.port_breadcrumbs .btn1{
  position: absolute;
  background-color: #16A068;
  left:-100px;
  width: 100%;
  margin: auto;
  border-width: 0px;
  padding: 10px 30px 10px 30px;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.port_breadcrumbs .btn1:hover{
  background-color: #000;
  color: #fff;
}
.port_breadcrumbs .btn2{
  position: absolute;
  background-color: #000;
  color: #fff;
  left:94px;
  width: 100%;
  margin: auto;
  border-width: 0px;
  padding: 10px 30px 10px 30px;
  border-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.port_breadcrumbs .btn2:hover{
  background-color: #16a068;
  color: #fff;
}
.listed-gal-box{
  position: relative;
  display: inline-block;
  margin-left: 10px;
  
  /* border-color: #4116ff;
  border-width: 2px;
  border-style: dashed; */

  margin-bottom: 50px;

}
.floating-gallery{
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.03);
  z-index:1;
}
.floating-gallery-2{
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.25);
  z-index:1;
}
.captioner{
  position: absolute;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  bottom:0px
}
.float_cap{
  position: absolute;
  font-family: "Segoe UI";
  font-size: 12pt;
  padding-left: 12px;
  padding-right: 12px;
  bottom:0px;
}
.gal-image-item:hover{
  opacity: 0.75;
}

/* ABOUT PAGE */
.about-people{
  position: relative;
  /* background-color: #c4c4c4; */
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 50px;
}
.about-people li{
  /* background-color: #eafaff; */
  display: block;
  margin-bottom: 20px;
}
.about-people li li{
  display: inline-block;
  margin: 20px;

}
.ceo{
  position: relative;
  background-image: url("./css_imgs/24_with_shoppingbag.jpg");
  width: 250px;
  height: 250px;
  margin: auto;
  left: auto;
  border-radius: 50%;

  border-width: 2px;
  border-color: #ffffff; /*#16A068*/
  border-style: solid;
}
.vice-ceo{
  position: relative;
  background-image: url("./css_imgs/Freddy_boy.jpg");
  width: 220px;
  height: 220px;
  margin: auto;
  left: auto;
  border-radius: 50%;

  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
}
.main-people{
  position: relative;
  margin: auto;
  left: auto;

  /* border-width: 2px;
  border-color: #C4C4C4;
  border-style: dashed; */
}
.profsingular1{
  position: relative;
  background-image: url("./css_imgs/12_cool.jpg");
  width: 150px;
  height: 150px;
  margin: auto;
  left: auto;
  border-radius: 50%;

  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
}
.profsingular2{
  position: relative;
  background-image: url("./css_imgs/16_puttingon_makeup_1.jpg");
  width: 150px;
  height: 150px;
  margin: auto;
  left: auto;
  border-radius: 50%;

  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
}
.profsingular3{
  position: relative;
  background-image: url("./css_imgs/5_in_martialart_uniform_wBlackbelt.jpg");
  width: 150px;
  height: 150px;
  margin: auto;
  left: auto;
  border-radius: 50%;

  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
}
.profsingular4{
  position: relative;
  background-image: url("./css_imgs/17_exercising_1.jpg");
  width: 150px;
  height: 150px;
  margin: auto;
  left: auto;
  border-radius: 50%;

  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
}
.profsingular5{
  position: relative;
  background-image: url("./css_imgs/4_with_baseball_bat&uniform.jpg");
  width: 150px;
  height: 150px;
  margin: auto;
  left: auto;
  border-radius: 50%;

  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
}
.profsingular6{
  position: relative;
  background-image: url("./css_imgs/sasha.png");
  width: 150px;
  height: 150px;
  margin: auto;
  left: auto;
  border-radius: 50%;

  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
}
.profsingular7{
  position: relative;
  background-image: url("./css_imgs/lev.png");
  width: 150px;
  height: 150px;
  margin: auto;
  left: auto;
  border-radius: 50%;

  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
}
.profsingular8{
  position: relative;
  background-image: url("./css_imgs/carly.png");
  width: 150px;
  height: 150px;
  margin: auto;
  left: auto;
  border-radius: 50%;

  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
}
.profsingular{
  position: relative;
  background-image: url("./css_imgs/12_cool.jpg");
  width: 150px;
  height: 150px;
  margin: auto;
  left: auto;
  border-radius: 50%;

  border-width: 2px;
  border-color: #C4C4C4;
  border-style: solid;
}

/*GUEST BOARD*/
.guest-upload-btn{
  position:absolute; 
  top:52px; 
  right:20px;
  background-color:#000; 
  color: white;  
  border-width:0px; 
  border-radius:5px;
  padding: 10px 20px 10px 20px;
}
.guest-upload-btn:hover{
  background-color:#16a068; 
  color: white;  
}
.guest_introcaution{
  position: relative;
  background-color: #000000;
  padding: 10px 0px 10px 30px;
  width: 890px;
  color: white;
  border-color: #e0e0e0;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  height:88.6px;
}
.guest_introcaution button{
  position: absolute;
  background-color: #16A068;
  width: 28px;
  height:26px;
  top:-2px;
  right:-32px;
  font-weight: bold;
  color:#e0e0e0;
  border-color: #e0e0e0;
  border-width: 1px;
  border-radius: 7px;
  border-style: solid;
  font-family: 'Baloo';
  color:#000000;
}
.guest_introcaution button:hover{
  font-weight: bold;
  color:#ffffff;
}
.guest_profile{
  position: relative;
  /* background-color: #f5f5f5; */
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 100px;
  /* border-color: #16a54b;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  z-index: 0; */
}
.guest-chat-btn{
  position:absolute; 
  background-color:#16A068; 
  font-size:13pt; 
  color:#ffffff;  
  top:24px; 
  right:20px; 
  padding: 12px 30px 12px 30px;
  border-radius:5px; 
  border-width:0px;
  border-color: #16A068;
}
.guest-chat-btn:hover{
  background-color:#000000; 
  color:#16A068; 
  border-color: #fff;
}
#guest-message{
  outline: none;
}
.guest-update-payment-btn{
  position:relative;
  background-color:#16A068; 
  color:#fff;
  top:20px;
  float:right;
  border-radius:20px; 
  border-width:0px;
  padding:10px 30px 10px 30px;
  cursor: pointer;
}
.guest-update-payment-btn:hover{
  background-color: #000;
  color: #16A068;
}
.guest-entry-chat{
  position: inherit;
 padding:10px 20px 10px 30px;
 border-radius: 5px;
 cursor: pointer;
}
.guest-entry-chat:hover{
  background-color: #f1f1f1;
}
.status-progress{
  position: relative;
  margin: auto;
  left:60px;
}
.status-progress li{
  display: inline-block;
  padding-right: 110px;
}
.paymethods li{
  display: inline-block;
  padding-right: 20px;
  padding-bottom: 20px;
}
.profile_display{
  position: absolute;
  top:3px;
}
.profile_directory{
  background-color: #000000;
  display: inline-block;
  padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  margin-right: 0px;
  padding-bottom: 10px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.profile_directory a{
  color: white;
  text-decoration: none;
}
.profile_directory:hover{
  background-color:#16A068;
  text-decoration: none;
}
.detail_content{
  position: relative;
  background-color: rgb(255, 255, 255);
  top: 50px;
  width: 100%;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.02), 0 5px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  border-width: 0px;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-bottom: 80px;
}
.container{
  position: relative;
  margin: 50px 50px 70px 50px;

  /* border-style: dotted;
  border-color: #19CB5B;
  border-width: 1px; */
  
  padding-bottom: 20px;
}
.tips-box-space-collapsed{
  position: relative;
  background-color: #000;
  top: 50px;
  width: 100%;
  height: 0px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.02), 0 5px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  border-width: 0px;
  padding-bottom: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.tips-box-space-expand{
  position: relative;
  background-color: #16A068;
  top: 50px;
  width: 100%;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.02), 0 5px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  border-width: 0px;
  padding-bottom: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.tips-bg-image{
  position:relative; 
  margin:auto;

  background-image: url("./css_imgs/guest_tips_src.png");
  top:20px;
  right:-5px;
  width: 700px;
  height:480px;
}
.jolme5d_gooder{
  position: absolute;
  top:0px;
  right:-1px;
}
.jolme5d_team{
  position: absolute;
  top:25px;
  right:50px;
}
.profile_image{
  position: relative;
  top:10px;
  left:-20px;
  width: 138px;
  height: 138px;
  background-color: #c4c4c4;
  border-radius: 50%;
}
.user_name{
  position: absolute;
  top:10px;
  left:135px;
  font-family: "Segoe UI";
  font-size: larger;
}
.user_id{
  position: absolute;
  top:60px;
  left:135px;
  font-size: 7pt;
}
.user_role{
  position: absolute;
  top:72px;
  left:135px;
  font-size:11pt;
  font-weight: bold;
}
.vertical_line {
  position: relative;
  border-left: 1px solid #C1C1C1;
  top:-20px;
  height: 140%;
  position:absolute;
  left: 40.5%;
}
.updator_box{
  position: absolute;
  top:-20px;
  right: -12px;
  width: 488px;

  /* border-width: 2px;
  border-style: dotted;
  border-color: #61dafb; */
}
.f_updator-s{
  position: relative;
  float: right;
  right:92px;
  margin-top: 20px;
}

.f_updator-s input{
  margin-left: 16px;
  width: 228px;
  height: 22px;
  padding-left: 10px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #C1C1C1;
}
.f_updator-s input:focus{
  outline: none;
}

.f_updator{
  position: relative;
  float: right;
  right:0px;
  margin-top: 20px;
}
.f_updator input{
  margin-left: 16px;
  width: 228px;
  height: 22px;
  padding-left: 10px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #C1C1C1;
}
.f_updator input:focus{
  outline: none;
}
.updator_btn-s{
  position: relative;
  background-color: #16A068;
  top:-32px;
  border-width: 0px;
  width: 76px;
  height: 26px;
  border-radius: 5px;
  color: white;
  margin-left: 16px;
  
}
.updator_btn-s:hover{
  background-color: #000000;
  border-width: 0px;
  width: 76px;
  height: 26px;
  border-radius: 5px;
  color: white;
  margin-left: 16px;
}
.f_updator .updator_btn{
  background-color: #16A068;
  border-width: 0px;
  width: 76px;
  height: 26px;
  border-radius: 5px;
  color: white;
  margin-left: 16px;
}
.f_updator .updator_btn:hover{
  background-color: #000000;
  border-width: 0px;
  width: 76px;
  height: 26px;
  border-radius: 5px;
  color: white;
  margin-left: 16px;
}

.orderpick{
  position: relative;
  width: 803px;
  background-color: #EFEFEF;
  padding-top: 10px;
  padding-bottom: 20px;
  border-radius: 5px;
}
.user_view_btn{
  position: absolute;
  background-color: #16A068;
  top:23px;
  right: 35px;
  width: 76px;
  height: 26px;
  color: white;

  border-width: 0px;
  border-radius: 5px;
}
.orderdetailsintro{
  position: relative;
  padding:5px 20px 0px 20px;
}
.orderdetailspace{
  position: relative;
  background-color: white;
  width: 90%;
  margin-top: 20px;
  margin-left: 20px;
  padding:10px 20px 20px 20px;
}

/*CLIENT DASH*/
.current-btn{
  padding: 2px 10px 1px 10px;
  border-width: 0px;
  background-color: #16A068;
  color:white;
  font-size:9pt;
  cursor: pointer;
  border-radius: 3px;
}
.current-btn:hover{
  background-color: #000;
  color:white;
  font-size:9pt;

}
.depo-placeholder::placeholder{
  color:#16A068
}
.depo-btn{
  background-color: #16A068;
  padding:5px 20px 5px 20px ;
  color: white;
  border-width: 0px;
  margin-left: 1px;
}
.depo-btn:hover{
  background-color: #000000;
}
.depo-clearclose-btn{
  background-color: #0c6d46;
  padding:5px 20px 5px 20px ;
  color: white;
  border-width: 0px;
  margin-left: 1px; 
}
.depo-clearclose-btn:hover{
  background-color: #000000;
}
.switch-buyer{
  background-color: #16A068;
  color:white;
  padding:5px 20px 5px 20px;
  border-width: 0px;
  border-radius: 20px;
}
.switch-buyer:hover{
  background-color: #000000;
  color:white;
}
.switch-account{
  background-color: #16A068;
  color:white;
  padding:2px 20px 5px 20px;
  border-width: 0px;
  border-radius: 20px;
}
.switch-account:hover{
  background-color: #000000;
  color:white;
}
.intro-prof{
  position: relative;
  background-color: #ebebeb;
  width: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 50px;
  padding: 10px 10px 10px 10px;
}
.client-fader{
  position: relative;
  width: 100%;
  height: 200px;
  background-color: rgba(0,0,0,0.0);
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 10px;
}
.client-prev-projects{
  position: relative;
  width: 100%;
  background-color: #f4f4f4;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 30px;
  padding: 5px 5px 5px 10px;
  cursor:pointer;
}
.client-dash{
  position: relative;
  width: 100%;
  background-color: #f4f4f4;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 5px 5px 5px 10px;
  text-align: center;
}
.currency-list-price li{
  display: block;
}
.currency-list-price li b{
  text-align: left;
}
.profile-picture-space{
  position: absolute;
  width: 150px;
  height: 150px;
  background-color: #f4f4f4;
  border-radius: 100px;
  cursor: pointer;
}
.profile-semi-details{
  position: absolute;
  left: 180px;
  height: 150px;
  
  /* background-color: #f4f4f4; */

  /* border-width: 1px;
  border-style: dashed;
  border-color: rgba(22,66,77, 0.2); */
}
.deposit-close-btn{
  position: absolute;
  right: 0;
  background-color: #0a5336;
  color: #fff;

  border-width: 0px;
  border-radius: 5px;
  padding: 10px 25px 10px 25px;
}
.deposit-close-btn:hover{
  background-color: #000000;
  color: #108053;
}

.client-deposit-btn{
  position:absolute; 
  top:50px; 
  right:182px; 
  background-color:#16a068; 
  border-width:0px; 
  padding:10px 15px 10px 15px; 
  cursor:pointer; 
  color:white;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.client-deposit-btn:hover{
  background-color:#000000; 
  color: #16a068;
  cursor:pointer; 
  color:white;
}

.employee-chatman-btn{
  position:relative; 
  top:50px; 
  right:301px; 
  width: 170px; 
  border-width:0px; 
  padding:10px 10px 10px 10px; 
  cursor:pointer; 
  color:white;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  
  height: 100px;
  background-color: #16a068;
  animation-name: chatteu;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes chatteu {
  0%   {background-color:green; right:301px; top:50px;}
  25%  {background-color:lime; right:301px; top:50px;}
  50%  {background-color:black; right:301px; top:50px;}
  75%  {background-color:lime; right:301px; top:50px;}
  100% {background-color:black; right:301px; top:50px;}
}
.employee-chatman-btn:hover{
  background-color:#000000; 
  color: #16a068;
  cursor:pointer; 
  color:white;
}

.employee-withdraw-btn{
  position:absolute; 
  top:50px; 
  right:130px; 
  background-color:#16a068; 
  border-width:0px; 
  padding:10px 15px 10px 15px; 
  cursor:pointer; 
  color:white;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.employee-withdraw-btn:hover{
  background-color:#000000; 
  color: #16a068;
  cursor:pointer; 
  color:white;
}

.client-wallet-btn{
  position:absolute; 
  top:50px; 
  right:98px; 
  background-color:#16a068; 
  border-width:0px; 
  padding:10px 15px 10px 15px; 
  cursor:pointer; 
  color:white;
}
.client-wallet-btn:hover{
  background-color:#000000; 
}
.wallet-set-primary-btn{
  position:absolute; 
  top:24px;
  left:425px; 
  background-color:#16a068; 
  border-width:0px; 
  padding:3px 20px 3px 20px; 
  cursor:pointer; 
  color:white; 
  border-radius: 3px;
  width: 230px;
}
.wallet-set-primary-btn:hover{
  background-color:#000000; 
}
.wallet-balance-list li{
  display: block;
  width: 210px;
  padding-bottom: 10px;
}
.client-updateinfo-btn{
  position:absolute; 
  top:50px; 
  right:0px;
  background-color:#16a068; 
  border-width:0px; 
  padding:10px 20px 10px 20px;
  cursor:pointer; 
  color:white;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.client-updateinfo-btn:hover{
  background-color:#000000; 
  color: #16a068;
  cursor:pointer; 
  color:white;
}
.wallet-switcher-tab-btn{
  position:relative; 
  background-color:#000000; 
  border-width:0px; 
  padding:5px 20px 20px 20px;
  cursor:pointer; 
  color:white;
  border-top-left-radius: 3px;
}
.wallet-switcher-tab-btn:hover{
  background-color:#fff; 
  color:#16A068;
}
.change-primary-currency-btn{
  position:relative; 
  background-color:#16a068; 
  border-width:0px; 
  padding:5px 20px 5px 20px;
  cursor:pointer; 
  color:#fff;
  border-radius: 3px;
}
.change-primary-currency-btn:hover{
  background-color:#000000; 
}
.project-single{
  position: relative;
  width: 96%;
  height: 100px;
  background-color: white;
  margin: auto;
  left: auto;
  padding-top: 5px;
  padding-bottom: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px  2px 0 rgba(0, 0, 0, 0.19);
}
.single-spanned{
  position: relative;
  left:10px;
  width: 100%;
  height: 100px;

  /* border-width: 1px;
  border-color: #108053;
  border-style: dashed; */
}
.single-spanned li{
  position: relative;
  text-align: justify;
  float: left;
  display: inline-block;
  padding-right:20px;
  margin-right: 20px;
}
.client-allocate-btn{
  background-color: #16A068;
  color:#fff;
  font-size: 9pt;
  border-width: 0px;
  border-radius: 10px;
  padding: 2px 15px 2px 15px;
  cursor: pointer;
}
.client-allocate-btn:hover{
  background-color: #000000;
}
.client-allocated-btn{
  background-color: #000000;
  color:#fff;
  font-size: 9pt;
  border-width: 0px;
  border-radius: 10px;
  padding: 2px 15px 2px 15px;
  cursor: pointer;
}
.client-allocated-btn:hover{
  background-color: #000000;
}
.wallet-deposit-btn{
  background-color: #16A068;
  border-radius: 2px;
  border-width: 0px;
  padding-top:3px; 
  padding-bottom: 3px;
  margin: 1px 1px 1px 1px;
}
.wallet-deposit-btn:hover{
  background-color: #000000;
}
.profile-semi-details-2{
  position: absolute;
  top:60px;
  left: 465px;
  width: 200px;
  height: 150px;
  /* background-color: #f4f4f4; */

  /* border-width: 1px;
  border-style: dashed;
  border-color: rgba(22,66,77, 0.2); */
}
.profile-semi-details-3{
  position: absolute;
  top:15px;
  right:0px;
  width: 150px;
  height: 150px;
  /* background-color: #f4f4f4; */

  /* border-width: 1px;
  border-style: dashed;
  border-color: rgba(22,66,77, 0.2); */
}
.client-activity-entry{
  cursor: pointer;
}
.client-activity-entry:hover{
  background-color:#f8f8f8;
}
.talk-to-manager-btn{
  background-color: #16A068;
  color:#01ff5e;
  padding: 3px 12px 3px 12px;
  border-radius: 10px;
  border-width: 0px;
  cursor: pointer;
}
.talk-to-manager-btn:hover{
  background-color: #16A068;
  color: #fff;
}

/*PROFILE SYSTEM*/
.system-control{
  position: relative;
}
.system-control li{ 
  position: relative;
  display: inline-table;
  margin: auto;
  left: auto;
  padding-right: 40px;
  width: 20%;
}
.control-area{
  position: relative;

  margin-top: 0px;
  margin-bottom: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: #c1c1c1;
  border-radius: 5px;

}

/*PROJECT ACTIVITY*/
.project-profile{
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;

  border-width: 1px;
  border-style: solid;
  border-color: #f1f1f1;
  border-radius: 5px;
}
.activity-space{
  position: relative;
  background-color: #f1f1f1;
  margin-top: 25px;
  margin-bottom: 100px;
  padding-bottom: 15px;

  border-width: 1px;
  border-style: solid;
  border-color: #f1f1f1;
  border-radius: 5px;
}
.goback-todash{
  font-size:12pt;
  color:#16a068;
  text-decoration: none;
}
.goback-todash:hover{
  font-size:12pt;
  color:#16a068;
  text-decoration: underline;
}

.gonext-toproject{
  position:relative;
  float: right;
  font-size:12pt;
  color:#16a068;
  text-decoration: none;
}
.gonext-toproject:hover{
  font-size:12pt;
  color:#16a068;
  text-decoration: underline;
}

.project-diverse li{
  display: inline-block;
  padding-inline: 100px;
}
.project-diverse li a{
  text-decoration: none;
}
.activity-room{
  position: relative;
  background-color: white;
  margin: auto;
  left: auto;
  width: 96%;
  margin-bottom: 50px;
  
  border-radius: 5px;

  margin-top: 0px;
  margin-bottom: 25px;
  padding-top: 25px;
  padding-bottom: 25px;

  box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px  2px 0 rgba(0, 0, 0, 0.19);
}
.content-space{
  position: relative;
  margin: auto;
  left: auto;
  width: 94%;

  border-width: 1px;
  border-style: solid;
  border-color: #f1f1f1;
}
.project-list-details{
  position: relative;
  top:10px;
  left:-30px;
}
.project-list-details li{
  float: left;
  display: inline-block;
  padding-right: 40px;
}
.handler-profile{
  position:relative; 
  margin:auto;
  width:100px; 
  height:100px; 
  border-radius:50px; 
  background-color:#f1f1f1;
  cursor: pointer;
}

.chat-entry-1{
  outline-color: #fff;
  padding: 5px 0px 5px 5px;
}

.chat-entry-1:focus{
  outline-color: #16a068;
}
.activity-smiley-space{
  width: 101%;
  height: 50px;
  margin-top: 10px;
}
.activity-submit-btn{
  background-color: #16A068;
  color: white;
  padding: 5px 30px 5px 30px;
  border-width: 0px;
  border-radius: 20px;
}
.activity-submit-btn:hover{
  background-color: #000;
}
.incredement-btn{
  background-color: #16A068;
  padding:5px 5px 5px 5px;
  border-radius: 5px;
  color: white;
  border-width: 0px;
}
.incredement-btn:hover{
  background-color: #000;
  color: white;
}
.attached-img:hover{
  opacity: 0.6;
}
/*PROFILE UPDATE*/
.updater-profile-space{
  position: relative;

  margin-top: 50px;
  margin-bottom: 50px;

  border-color: #d1d1d1;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;

  padding-bottom:50px;
}

.field-blank-space{
  position:relative; 
  margin:auto; 
  width:80%; 
  height:20px;
  margin-top:20px;
  margin-bottom:20px;
  text-align:center;
}
.profile-delete-space button{
  background-color:#16a068;
  color:white; 
  border-width:0; 
  padding:10px 30px 10px 30px;
  border-radius: 20px;
}
.profile-delete-space button:hover{
  background-color:#000000;
  color:white; 
  border-width:0; 
  padding:10px 30px 10px 30px;
}
.profile-input-field:focus{
  outline: #16A068;
}

.upload-close-btn{
  position:absolute;
  background-color: #16A068;
  color:#fff;

  right:0px;
  border-width: 0px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;

  padding: 10px 20px 10px 20px;
}
.upload-close-btn:hover{
  background-color: #000000;
  color:#16A068;
}

.update-prof-c-btn{
  position:relative;
  right:20px;
  background-color:#000;
  color:white; 
}
.update-prof-c-btn:hover{
  background-color:#16a068;
  color:white; 
}

.update-prof-u-btn{
  background-color:#16a068;
  color:white; 
}
.update-prof-u-btn:hover{
  background-color:#000;
  color:white; 
}

/*EMPLOYEE AREA*/
.update-project-status-btn{
  position: absolute;
  width: 200px;
  top:22px;
  left:0px;
  padding: 5px 10px 5px 10px;
  background-color: #16A068;
  border-radius: 20px;
  border-width: 0px;
  color: #fff;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.update-project-status-btn:hover{
  background-color: #000000;
}

.view-client-project-btn{
  position: absolute;
  width: 100px;
  top:22px;
  left:201px;
  padding: 5px 10px 5px 10px;
  background-color: #000;
  border-radius: 20px;
  border-width: 0px;
  color: #fff;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.view-client-project-btn:hover{
  background-color: #16A068;
}

.payment-choice-btn{
  margin-top:10px;
  margin-bottom:10px;
  padding:15px 20px 15px 20px;
  width:100%;
  border-width: 0px;
  border-style: none;
  border-radius: 4px;
  background-color: #303030;
  color:#fff
}
.payment-choice-btn:hover{
  background-color: #000;
  color:#fff
}
.back-payment-btn{
  position: relative;
  width:50px;
  height:50px;
  background-color: #16a068;
  border-width: 0px;
  border-style: none;
  border-radius: 50%;
  color:white;
  margin: 20px 20px 20px 0px;
  left:auto;
}
.back-payment-btn:hover{
  background-color: #000;
}
.off_paypal_btn{
  width: 100%;
  background-color: rgba(255,196, 57);
  height: 42px;
  border-width: 0px;
  border-radius: 4px;
  cursor: pointer;
  font-size: larger;
  font-style: italic;
  font-weight: bolder;
}
.off_paypal_btn:hover{
  background-color: rgba(255,255, 0);
}
.mongo_btn{
  background-color:#16a068; 
  width:50.7%;  
  color:#fff; border-width:0px; 
  padding:10px 20px 10px 20px;
  border-radius: 3px;
}
.mongo_btn:hover{
  background-color:#000; 
}
.mongo_btn_dead{
  background-color:#dfdfdf; 
  width:50.7%;  
  color:#fff; border-width:0px; 
  padding:10px 20px 10px 20px;
  border-radius: 3px;
}
.mongo_btn_dead:hover{
  background-color:#dfdfdf; 
}

.extend-btn{
  background-color:#16a068; 
  border-width:0px; 
  color:#fff;
  font-size: 0.7em;
  padding: 5px 10px 5px 10px;
  border-radius:15px
}
.extend-btn:hover{
  background-color:#000000; 
  color:#16a068
}
.leave_feedback-btn{
  background-color:#16a068; 
  color:#fff;  
  border-width:0px; 
  border-radius:20px;  
  padding:5px 30px 5px 30px
}
.leave_feedback-btn:hover{
  background-color:#000; 
  color:#fff;  
}
.preview-btn{
  background-color:#000; 
  color:#fff;  
  border-width:0px; 
  border-radius:20px;  
  padding:5px 30px 5px 30px
}
.preview-btn:hover{
  background-color:#16a068; 
  color:#fff;  
}