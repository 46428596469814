#MOBILE-MAIN-BODY{
    position: absolute;
    width: auto;
    width: 100%;
}
.header_space{
    position: fixed;
    background-color:rgba(0,0,0,0.9);
    width: 100%;
    height: 50px;
    z-index: 1;
}
.mobile_menulist{
    position: absolute;
    left: -20px;
}

.mobile_menulist li{
    display: block;
    padding-bottom: 20px;
    font-size: 22pt;
    text-decoration: none;
}
.mobile_menulist li a{
    text-decoration: none;
}
.mobile_menulist li a:hover{
    text-decoration: underline;
    color: #16A068;
}
.mobile_menulist_2{
    position: absolute;
    top: 0px;
    right:20px;
    text-align: right;
}
.mobile_menulist_2 li{
    display: block;
    padding-bottom: 20px;
    font-size: 22pt;
    text-decoration: none;
}
.mobile_menulist_2 li a{
    text-decoration: none;
    color: #16A068;
}
.mobile_menulist_2 li a:hover{
    text-decoration: underline;
}
.mobile-landing{
    position: relative;
    margin:auto;
    left:auto;
    width: 100%;
    text-align: center;
    /* background-image: url('../src/css_imgs/mobile_landing_onlyBG.svg');
    background-repeat: no-repeat; */

    /* border-width: 1px;
    border-style: dotted;
    border-color: aqua; */
}
.mobile-features{
    position: relative;
    margin:auto;
    left:auto;
    width: 80%;
    text-align: center;
    margin-bottom: 70px;
    /* background-image: url('../src/css_imgs/mobile_landing_onlyBG.svg');
    background-repeat: no-repeat; */

    /* border-width: 1px;
    border-style: dotted;
    border-color: aqua; */
}
.mobile-main-space{
    position: relative;
    margin: auto;
    left: auto;
    width: 100%;
    background-color: white;

    padding-top: 80px;
    margin-bottom: 50px;

    /* border-width: 1px;
    border-style: dotted;
    border-color: aqua; */
}
.m_form_title{
    position: absolute;
    width: 100%;
    font-size: 20pt;
    font-weight: bold;
    text-align: center;
    color: #16A068;
}
.m_form_forget{
    position: absolute;
    width: 100%;
    font-size: 14pt;
    font-weight:lighter;
    text-align: center;
    color: #16A068;
}
.mobile_register_form{
    position: relative;
    margin: auto;
    top:-10px;
    width: 95%;
    margin-top: 10px;
    /* border-width: 1px;
    border-style: dotted;
    border-color: red; */
}
.m_register_fields{
    position: relative;
    width: 100%;
    height: 60px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(218, 218, 218);
}
.m_register_fields label{
    position: relative;
    font-size: 8pt;
    color: grey;
    top:2px;
    left: 15px;
    width: 90%;
    height: 40px;
}
.m_register_fields input{
    position: relative;
    font-size: 18pt;
    padding-left: 5px;
    top:5px;
    left: 15px;
    width: 90%;
    height: 30px;
    border-width: 0px;
    border-radius: 3px;
    color: #16A068;
}
.m_register_fields input:focus{
    outline: none;
}
.m_register_btn {
    position: relative;
    background-color: #16A068;
    width: 100%;
    height: 60px;
    font-family: "Roboto", sans-serif;
    color: white;
    font-size: 18pt;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(218, 218, 218);
    margin-bottom: 6px;
}
.m_register_cbtn {
    position: relative;
    background-color: #ffffff;
    width: 100%;
    height: 60px;
    font-family: "Roboto", sans-serif;
    color: rgb(168, 168, 168);
    font-size: 18pt;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(218, 218, 218);
}
/*ABOUT*/
.mobile_about_people{
    position: relative;
    margin: auto;
    left: auto;
    width: 80%;
    justify-items: center;
    margin-top: 50px;
}
.mobile_about_people li{
    position: relative;
    margin: auto;
    display: block;
}

/*SERVICES*/
.quotation_rounder_mobile{
    position:fixed;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.1);
    z-index: 1;
  }

.quotation_floater_mobile{ /*floating quoter*/
    position: relative;
    margin: auto;
    top: 50px;
    left: auto;
    width: 600px;
    background-color: #ffffff;
    padding-bottom: 20px;
    border-radius: 5px;
    /* border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: #ffffff;
    text-align: center; */
  }

/*FOOTER*/
.footer_space{
    position: relative;
    background-color: black;
    width: 100%;
    height: 400px;
}

/* LOGIN */
.wrong_credential_btn{
    position:relative;
    width: 50%;
    height: 35px;
    background-color: #16A068;
    color:#fff;
    border-width: 0px;
    border-radius: 5px;
}
.wrong_credential_btn:active{
    background-color: #28d348;
}
.mobile_data_update_fields {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }
  
.mobile_data_update_fields:focus {
    border: 3px solid #555;
  }
.mobile_d_u_f_btn{
    background-color: #16A068;
    border-width: 0px;
    border-radius: 5px;
    color:#fff;
    font-family: "Baloo";
    font-size: 16pt;
}
.mobile_d_u_f_btn:hover{
    background-color: #28d348;
}

/* HEADER */
.mobile_header_menu{
    position:absolute;
    padding-top: 30px;
    width: 75%;
    margin:auto;
    text-align: center;
}
.mobile_header_menu li{
    display: block;
    color:#dfdfdf;
    font-size: 22pt;
    padding-bottom: 20px;
}
.mobile_header_menu li:active{
    color:#fff;
}

/*ERROR*/
.mobile_btn_error{
    padding: 10px 20px 10px 20px;
    background-color: #000;
    color:#fff;
    border-width: 0px;
    border-radius: 5px;
}
.mobile_btn_error:hover{
    background-color: #16A068;
}

/*PAYMENT PROCESS*/
.mobile_continue_btn{
    background-color: #16A068;
    color:white;
    border-radius: 25px;
    padding: 15px 50px 15px 50px;
    border-width: 0px;
}
.mobile_continue_btn:hover{
    background-color: #000;
}

/* CLIENT PROJECT VIEW */
.project-single{
    position: relative;
    width: 96%;
    height: 100px;
    z-index: 7;
    background-color: white;
    margin: auto;
    left: auto;
    padding-top: 5px;
    padding-bottom: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
    box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px  2px 0 rgba(0, 0, 0, 0.19);
  }
.single-spanned{
    position: relative;
    left:10px;
    width: 100%;
    height: 100px;
  
    /* border-width: 1px;
    border-color: #108053;
    border-style: dashed; */
  }
  .single-spanned li{
    position: relative;
    text-align: justify;
    float: left;
    display: inline-block;
    padding-right:20px;
    margin-right: 20px;
  }